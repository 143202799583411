<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('notification.notification') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(read_status)="data">
                      <span class="badge badge-warning" v-if="data.item.read_status === 1">{{$t('globalTrans.not_seen')}}</span>
                      <span class="badge badge-success" v-else>{{$t('globalTrans.seen')}}</span>
                    </template>
                    <template v-slot:cell(message)="data">
                      <router-link :to="{ path: data.item.url }" class="iq-sub-card">
                        <p class="mb-0" @click="notificationSeen(data.item.id)">{{ data.item.message.substring(0,50) + '...' }}</p>
                      </router-link>
                    </template>
                    <template v-slot:cell(action)="data">
                        <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 1" @click="remove(data.item)">
                            <i class="fas fa-toggle-on"></i>
                        </a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" v-else @click="remove(data.item)">
                            <i class="fas fa-toggle-off"></i>
                        </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      search: {
        training_type: ''
      }
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.training_type') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.training_type') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('notification.notification'), class: 'text-left' },
          { label: this.$t('globalTrans.url'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'message' },
          { key: 'url' },
          { key: 'read_status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'message' },
          { key: 'url' },
          { key: 'read_status' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, '/notification-received/list', params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            // this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    notificationSeen (id) {
      RestApi.getData(trainingElearningServiceBaseUrl, `/notification-seen/${id}`).then(response => {
        if (response.success) {
          const notificationList = this.$store.state.notificationList
          const allNot = []
          notificationList.forEach((element, key) => {
            if (element.id === id) {
              let totalNotification = this.$store.state.totalNotification
              totalNotification = totalNotification - 1
              if (totalNotification >= 0) {
                  this.$store.dispatch('setTotalNotification', totalNotification)
              }
            } else {
              allNot.push(element)
            }
          })
          this.$store.dispatch('setNotificationList', allNot)
        }
      })
    }
  }
}
</script>
